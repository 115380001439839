import React from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// @ts-ignore
import BackgroundImage from "../assets/signupBackground.jpg";
import Grid from '@material-ui/core/Grid';

import API from '../API';
import { globalContext } from '../GlobalContext';

/**
 * Login page
 * @param {*} props 
 * @author BrianCastor
 */
export default function Login(props) {
    const context = React.useContext(globalContext);
    const [lastName, setLastName] = React.useState(null);
    const [emailOrPhone, setEmailOrPhone] = React.useState(null);
    const [error, setError] = React.useState(null);

    //Redirect to /home if user is signed in
    if (context.isRegistered()) {
        context.navigateSafe('/home');
        return('');
    }

    function login() {
        setError(null);
        API.authenticateWithLastNameAndEmailOrPhone(lastName, emailOrPhone).then(data => {
           context.updateUserPreferences(data);
        }).catch(err => {
            //context.updateUserPreferences(null);
            setError(err);
        })
    }

    return (
        <div>
            <div style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),  rgba(0, 0, 0, 0.5)), url(${BackgroundImage})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', position: 'relative', height: '100vh' }}>
                <Container maxWidth="sm" style={{ paddingTop: '40px' }}>
                    <Paper component="form" style={{ padding: '30px' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <h3>Log into your account</h3>
                        </div>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField required label="Last Name" fullWidth defaultValue="" value={lastName} variant="outlined" onChange={(event) => setLastName(event.target.value)} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField required label="Email or Phone" fullWidth defaultValue="" value={emailOrPhone} variant="outlined" onChange={(event) => setEmailOrPhone(event.target.value)} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth variant="contained" color="primary" onClick={() => login()}>Login</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <span style={{color:'red'}}>{error}</span>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </div>
        </div>
    )
}