import React from "react";

import Layout from "../components/layout"
import Seo from "../components/seo"
import Login from '../components/login';

const LoginPage = (data) => (
  <Layout>
    <Seo title="Login" />
    <Login/>
  </Layout>
)

export default LoginPage;